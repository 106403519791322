import React, { useState, useEffect } from 'react';
import { useForm, ValidationError } from '@formspree/react';
import { GoLocation } from '@react-icons/all-files/go/GoLocation';
import { MdLocalPostOffice } from '@react-icons/all-files/md/MdLocalPostOffice';
import { FiPhoneCall } from '@react-icons/all-files/fi/FiPhoneCall';
import { FaLinkedin } from '@react-icons/all-files/fa/FaLinkedin';
import { FaFacebook } from '@react-icons/all-files/fa/FaFacebook';
import { AiFillTwitterCircle } from '@react-icons/all-files/ai/AiFillTwitterCircle';
import { SiMinutemailer } from '@react-icons/all-files/si/SiMinutemailer';

export const Contact = () => {
    const defaultValues = {
        name: '',
        email: '',
        message: '',
    };
    const [values, setValues] = useState(defaultValues);

    const handleOnChange = (e: { target: { name: any; value: any } }) =>
        setValues({ ...values, [e.target.name]: e.target.value });

    const [submissionState, handleSubmit] = useForm('xleazoob');
    const [showToaster, setShowToaster] = useState(true);

    useEffect(() => {
        if (submissionState.submitting) {
            setShowToaster(true);
        } else {
            setTimeout(() => setShowToaster(false), 6000);
        }
    }, [submissionState]);

    return (
        <section id="contact" className="grid grid-cols-1 md:grid-cols-2 mt-32 text-white">
            <div className="bg-pink-500 relative">
                <div className="max-w-xl md:float-right p-8 sm:p-16">
                    <div className="text-3xl font-bold">Contact Us</div>
                    <p className="mt-4">See how the Farmote System can work for you</p>
                    <p className="mt-16 flex align-middle gap-2">
                        <GoLocation />
                        Unit 4/93 Main South Road, Sockburn, Christchurch. 8042
                    </p>
                    <p className="mt-4 flex align-middle gap-2">
                        <MdLocalPostOffice />
                        PO Box 1125, Christchurch Central, Christchurch, 8140, New Zealand
                    </p>
                    <p className="mt-4 flex align-middle gap-2">
                        <FiPhoneCall />
                        <a href="tel:+6421743365">+64 (0) 021 743 365</a>
                    </p>
                    <p className="mt-4 flex align-middle gap-2">
                        <SiMinutemailer />
                        <a href="mailto:info@farmote.com">info@farmote.com</a>
                    </p>

                    <p className="mt-4 flex align-middle gap-2">
                        <a target="_blank" href="https://nz.linkedin.com/company/farmote-systems">
                            <FaLinkedin size="2rem" />
                        </a>
                        <a target="_blank" href="https://twitter.com/farmote">
                            <AiFillTwitterCircle size="2rem" />
                        </a>
                        <a target="_blank" href="https://facebook.com/farmote">
                            <FaFacebook size="2rem" />
                        </a>
                    </p>

                    <div className="flex flex-col"></div>
                </div>
            </div>
            <div className="bg-gray-800 relative">
                <div className="max-w-xl md:float-left p-8 sm:p-16">
                    <div className="text-3xl font-bold">Send A Message</div>
                    <p className="mt-4">
                        If you are interested in getting Farmote Systems' solution installed on your farm, or receiving
                        updates on our progress, please send us a message
                    </p>
                    <form
                        onSubmit={(e) => {
                            handleSubmit(e);
                            setValues(defaultValues);
                        }}
                    >
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                            <input
                                type="text"
                                name="name"
                                required
                                value={values.name}
                                placeholder="FIRST NAME"
                                className="p-3 rounded-md bg-gray-300 text-gray-800"
                                onChange={handleOnChange}
                            />
                            <input
                                type="email"
                                id="email"
                                name="email"
                                required
                                value={values.email}
                                placeholder="EMAIL"
                                className="p-3 rounded-md bg-gray-300 text-gray-800"
                                onChange={handleOnChange}
                            />
                            <ValidationError
                                prefix="*Email"
                                field="email"
                                errors={submissionState.errors}
                                className="text-red-500"
                            />
                            <textarea
                                rows={5}
                                name="message"
                                required
                                value={values.message}
                                placeholder="MESSAGE"
                                className="p-3 md:col-span-2 rounded-md bg-gray-300 text-gray-800"
                                onChange={handleOnChange}
                            />
                            <ValidationError
                                prefix="*Message"
                                field="message"
                                errors={submissionState.errors}
                                className="text-red-500"
                            />
                            <input type="text" name="_gotcha" className="hidden" />
                            <button
                                className="p-4 bg-gray-600 w-full rounded-xl"
                                type="submit"
                                disabled={submissionState.submitting}
                            >
                                Send
                            </button>
                        </div>
                    </form>
                </div>
                {showToaster && submissionState.succeeded && (
                    <div className="absolute bottom-8 right-8">
                        <div className="flex items-center bg-green-500 border-l-4 border-green-700 py-2 px-3 shadow-md mb-2">
                            <div className="text-green-500 rounded-full bg-white mr-3">
                                <svg
                                    width="1.8em"
                                    height="1.8em"
                                    viewBox="0 0 16 16"
                                    className="bi bi-check"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
                                    />
                                </svg>
                            </div>
                            <div className="text-white max-w-xs ">Thank you! We will be in touch.</div>
                        </div>
                    </div>
                )}
            </div>
        </section>
    );
};
