import React, {useState} from 'react'
import Modal from 'react-modal'
import {MdClose} from '@react-icons/all-files/md/MdClose'
import Privacy from './privacy';
import Terms from './terms';

const customStyles = {
    content: {
        margin: 'auto auto',
        backgroundColor: '#ffffff',
        color: '#000000',
        minWidth: '80vw',
        minHeight: '80vh',
    },
    overlay: {
        zIndex: 1000,
        backgroundColor: 'rgba(0,0,0,0.8)',
    }
};

const Footer = () => {
    const [selectedLink, setSelectedLink] = useState(-1)
    return (<div className='static bottom-0 right-0'>
        <footer className="flex flex-row justify-end gap-4 text-xs p-4 text-secondary">
            © {new Date().getFullYear()}, Farmote Systems
            <a href="#" onClick={() => setSelectedLink(0)}>Privacy</a>
            <a href="#" onClick={() => setSelectedLink(1)}>Terms</a>
        </footer>
        <Modal
            isOpen={selectedLink > -1}
            onRequestClose={() => setSelectedLink(-1)}
            contentLabel=""
            style={customStyles}
        >
            <MdClose onClick={() => setSelectedLink(-1)} className="absolute right-4 top-4 text-2xl cursor-pointer text-black"/>
            {selectedLink === 0 && <Privacy/>}
            {selectedLink === 1 && <Terms/>}
        </Modal>
    </div>)
}

export default Footer