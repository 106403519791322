import React from 'react'
import './tandc.scss'

const Privacy = () => <div className="tc">
    <section>
        <h2>WEBSITE PRIVACY POLICY</h2>
        <p>Farmote is committed to ensuring compliance with the New Zealand Privacy Act 2020 and the European Union’s General Data Protection Regulation <strong>(GPDR)</strong>.</p>
        <p>This Privacy Policy applies to anyone who visits the Farmote Website or uses Farmote’s Services. From time to time, Farmote may change this Privacy Policy and will post the changes on the Farmote Website. If You don’t agree with the changes to the Privacy Policy then You may not be able to fully use Farmote’s Website and Services.</p>
    </section>
    <section>
        <p>
            <ol>
                <li>WHAT FARMOTE COLLECTS 
                    <ol>
                        <li>Farmote collects from You: 
                            <ol>
                                <li>Your Personal Information, including:
                                    <ol>
                                        <li>Your Sensitive Information;</li>
                                        <li>Your name;</li>
                                        <li>Your contact information, including email address;</li>
                                        <li>other information relevant to surveys You participate in and/or offers You accept; and</li>
                                        <li>information about Your preferences and activities on the Farmote Website; and</li>
                                    </ol>
                                </li>
                                <li>Personal Information (including Sensitive Information) about anyone else that You give to Farmote. </li>
                            </ol>
                        </li>
                        <li>When You disclose Your Personal Information to Farmote it must be accurate, complete, up-to-date and not misleading. If You give Farmote Personal information about someone else, then You must be authorised to do so and You must tell that person about this Privacy Policy.</li>
                    </ol>
                </li>
                <li>HOW FARMOTE COLLECTS YOUR PERSONAL INFORMATION
                    <ol>
                        <li>Farmote collects Your Personal Information only when needed and for lawful purposes connected with performing its functions or activities. You authorise the collection of Your Personal Information from Farmote when You: 
                            <ol>
                                <li>use Farmote Services on Your computer, mobile, tablet or any other electronic device;</li>
                                <li>create a user account with Farmote; </li>
                                <li>communicate with Farmote; </li>
                                <li>enter into any Farmote competitions and/or promotions.</li>
                            </ol>
                        </li>
                        <li>Farmote may also collect Your Personal Information from publicly available sources or from Third Parties from time to time.</li>
                    </ol>
                </li>
                <li>HOW FARMOTE WILL USE YOUR PERSONAL INFORMATION 
                    <ol>
                        <li>You authorise Farmote to use Your Personal Information (and where necessary), to disclose Your Personal Information to Third Parties for the following Purposes: 
                            <ol>
                                <li>for the Purposes of providing You with the Farmote Services, including to:
                                    <ol>
                                        <li>communicate with You, including to send promotional emails about new services, special offers, promotions or other information;</li>
                                        <li>provide Farmote Services to You;</li>
                                        <li>respond to Your queries regarding the Farmote Website or Services;</li>
                                        <li>verify Your identity when You create a user account with Farmote;</li>
                                        <li>administer Your account;</li>
                                        <li>provide You with a good user experience;</li>
                                        <li>develop new Services, features and/or Content; and</li>
                                        <li>keep You updated on things that are happening at Farmote;</li>
                                    </ol>
                                </li>
                                <li>for internal record keeping purposes;</li>
                                <li>to the extent necessary to perform the purposes specified in this Privacy Policy and to allow for the operation of Farmote’s business activities and functions;</li>
                                <li>for any other purposes You authorise; </li>
                                <li>for the purpose of Farmote selling all or most of Farmote’s assets; and</li>
                                <li>to comply with Farmote’s Legal Obligations. </li>
                            </ol>
                        </li>
                        <li>You agree that Farmote may share Your Personal Information with Third Parties to fulfil business transactions for the purposes specified in this Privacy Policy (for example providing customer services, sending marketing communications, and providing maintenance services). </li>
                    </ol>
                </li>
                <li>HOW LONG FARMOTE HOLDS YOUR PERSONAL INFORMATION </li>
                <p className="ml-8">Farmote will hold Your Personal Information for as long as Your Personal Information is required for the Purposes and for as long as is necessary to comply with its Legal Obligations. </p>
                <li>HOW FARMOTE STORES AND PROTECTS YOUR PERSONAL INFORMATION  </li>
                <p className="ml-8">Farmote will use reasonable care to protect Your Personal Information from unauthorised access, use, modification, disclosure, and loss.</p>
                <li>FARMOTE MAY TRANSFER YOUR PERSONAL INFORMATION TO OTHER COUNTRIES</li>
                <p className="ml-8">In order to provide You with the Farmote Services, Farmote may need to disclose and transfer Your Personal Information to Third Parties located in a different country from where You live. This may include countries located outside the European Economic Area (EEA) and/or New Zealand.  If those countries do not have data protection laws comparable to the data protection laws of Your country of residence, then Farmote will take reasonable steps to ensure that Your Personal Information (including any Sensitive Information) is protected in those countries.</p>
                <p>
                    <li>FOR THE PURPOSE OF THE GDPR
                        <ol>
                            <li>Farmote is the data controller for personal data collected by it in the provision of its Services, support and assistance to You as the user. If Farmote uses personal data that has been collected by a Third Party it will process the information as a data processor on behalf of the Third Party that collected Your Personal Information.</li>
                            <li><strong>Request access to Your Personal Information:</strong> You have the right to request access to any Personal Information that Farmote holds about You, the right to know the source of Your Personal Information and the processing methods used by Farmote. If Farmote cannot give You access to the Personal Information that You have requested, then Farmote will let You know the legal reasons for not disclosing Your Personal Information.  If You are not happy with the legal reasons provided, then You have the right to complain to the New Zealand Privacy Commissioner: www.privacy.org.nz/your-rights/how-to-complain/.</li>
                            <li><strong>Request correction of Your Personal Information:</strong> You have the right to request correction of the Personal Information that Farmote holds about You. Farmote will correct Your Personal Information if it is necessarily to ensure that Your Personal Information is accurate, up to date, complete and not misleading.  If Farmote decides that it is unnecessary to correct Your Personal Information, Farmote will let You know the legal reasons for its decision.  If You are not happy with those reasons, then You have the right to complain to the New Zealand Privacy Commissioner: www.privacy.org.nz/your-rights/how-to-complain/.</li>
                            <li><strong>Request to restrict the use and processing of or delete Your Personal Information:</strong> You have the right to request that Farmote delete Your Personal Information when it is no longer needed for the Purposes, or withdraw your authorisation for Farmote to use Your Personal Information. You also have the right to restrict Your Personal Information from being processed in the event You consider Your rights and freedoms outweigh our legitimate interests in using Your Personal Information.</li>
                            <li><strong>Data portability:</strong> If the GDPR applies, You have the right to request that Farmote transfer Your Personal Information from one electronic processing system to another electronic processing system.  </li>
                        </ol>
                    </li>
                </p>
                <p>
                    <li>MINORS</li>
                    <p className="ml-8">Farmote does not knowingly collect Personal Information from children (as defined by local laws) and does not target its Website and/or Services towards children.</p>
                </p>
                <p>
                    <li>WHAT HAPPENS IF YOU DON’T GIVE FARMOTE YOUR PERSONAL INFORMATION 
                        <ol>
                            <li>If You decide not to give Farmote Your Personal Information, or otherwise wish to withdraw your authorisation at any time, then You: 
                                <ol>
                                    <li>may not be able to use some of the Farmote Website and Services; </li>
                                    <li>won’t be kept updated on things that are happening at Farmote.</li>
                                </ol>
                            </li>
                        </ol>
                    </li>
                </p>
                <p>
                    <li>PRIVACY COMPLAINTS </li>
                    <p className="ml-8">If You think that Your privacy rights have been breached, You can make a written complaint to Farmote at info@farmote.com, or otherwise contact the NZ Privacy Commissioner at https://www.privacy.org.nz/your-rights/making-a-complaint/complaint-form/.</p>
                </p>
                <p>
                    <li>COOKIES
                        <ol>
                            <li>A cookie is a small file that can be placed on Your computer, mobile, tablet or any other electronic device when You visit the Farmote Website. Cookies allow the Website to recognise Your device. Farmote may use cookies in order to make it easier to use the Farmote Website and to help Farmote to analyse the profile of visitors and users in order to improve usability and features of the Farmote Website and Services.  </li>
                            <li>There are two types of cookies used by Farmote: 
                                <ol>
                                    <li><strong>Session cookies.</strong> These are used to store information about Your activities so You can move from one webpage to another. These cookies track Your movement from webpage to webpage of a website so that You do not have to provide the same information provided on earlier webpages. Session cookies only remain in the cookie file of Your browser until You close the browser.</li>
                                    <li><strong>Persistent cookies.</strong> These are used to store Your preferences to improve Your experience on future visits to the Farmote Website. For example, these cookies can remember log-in details or language preferences so that You don’t have to continually re-enter these each time You visit the website. Persistent cookies stay in the cookie file of Your browser after You have closed Your browser, and activate again when You next visit that website.</li>
                                </ol>
                            </li>
                            <li>To enable some of the features and functionality of the Farmote Website, You will need to permit Farmote to place cookies on Your device.  If You choose not to, then those features and functionality will not be available to You. You can remove or block cookies by using the settings in Your browser but it may affect Your ability to use the Farmote Website. </li>
                        </ol>
                    </li>
                </p>
                <p>
                    <li>DEFINITIONS</li>
                    <p className="ml-8">
                        <p><strong>Farmote</strong> means Farmote Limited, trading as Farmote Systems, a limited liability company incorporated under the laws of New Zealand;</p>
                        <p><strong>Farmote Services:</strong>  means any products, services, software, Content, features, functionalities and any other services provided to You via the Farmote Website;</p>
                        <p><strong>Farmote Website</strong> means the website (or websites) located at https://farmote.com and portal.farmote.com operated by Farmote from time to time;</p>
                        <p><strong>Content</strong> means all content delivered via the Farmote Website and Services including all text, software, scripts, graphics, photos, sounds, music, videos, audio-visual works or combinations of works, live streaming, interactive features and other materials;</p>
                        <p><strong>including</strong> means “including without limitation”;</p>
                        <p><strong>Legal Obligations</strong> means any legal or regulatory obligation that Farmote has under the laws of any relevant any country in relation to the collection, use, disclosure, storage or processing of Your Personal Information;</p>
                        <p><strong>Personal Information</strong> means any information that can be used to directly or indirectly identify an individual, and includes Sensitive Information, and references to “Your Personal Information” also includes any Personal Information that You provide to Farmote about someone else;</p>
                        <p><strong>Purposes</strong> means the purposes for which Your Personal Information is used and disclosed as specified in this Farmote Privacy Policy;</p>
                        <p><strong>Sensitive Information</strong> means information about Your racial or ethnic origin, political opinions, religious or philosophical beliefs, trade-union membership, health or sexual orientation;</p>
                        <p><strong>Third Parties</strong> means any person, company, body corporate, entity, governmental or regulatory authority in any country; and</p>
                        <p><strong>You</strong> means you as an individual and Your has a corresponding meaning.</p>
                    </p>
                </p>
            </ol>
        </p>
    </section>
</div>

export default Privacy