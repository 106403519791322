import React from 'react'
import './tandc.scss'

const Terms = () => <div className="tc">
    <section>
        <h2>WEBSITE TERMS OF USE</h2>
        <p>By visiting or using this Website You agree to be bound by these Terms. Where Your access and use is on behalf of another person (e.g. a company), You confirm that You are authorised to, and do in fact, agree to these Terms on that person’s behalf and that, by agreeing to these Terms on that person’s behalf, that person is bound by these Terms. If you do not agree to these Terms, You are not authorised to access and use the Website, and You must immediately stop doing so. We may change these Terms at any time and will post the changes on the Website. If You disagree with the changes then You must stop visiting and using this Website. </p>
    </section>
    <section>
        <h3>DEFINITIONS </h3>
        <p>In these Terms, the following words have the following meanings:</p>
        <p><strong>Our IP:</strong> means intellectual property owned or licensed by Us relating to Our Website, Products or Services, including the text, graphics, logos, icons, the software and any other material underlying or forming part of this Website or Our Products or Services and includes any rights to that intellectual property, including any copyright, trade or service mark, trade or business name, logos and any other distinctive brand features, design, patent, semiconductor or circuit layout right, computer code (including source, library, object, and executable code), inventions and logical sequences, in all cases whether registered or unregistered and anywhere in the world.</p>
        <p><strong>Privacy Statement:</strong> means Our Privacy Statement available at https://farmote.com.</p>
        <p><strong>Products:</strong> means any products provided by Us via this Website.</p>
        <p><strong>Services:</strong> means any services, features or functionalities provided by Us via this Website.</p>
        <p><strong>Website:</strong> means this website.</p>
        <p><strong>We, Us and Our:</strong> means Farmote Limited, trading as Farmote Systems.</p>
        <p><strong>You:</strong> means you as an individual and Your has a corresponding meaning.</p>
    </section>
    <section>
        <h3>ACCESS AND USE OF THE WEBSITE</h3>
        <p>You agree to access and use the Website and any Products and Services in accordance with these Terms and all applicable laws.</p>
        <p>We grant You the right to access and use the Services via the Website. This right is non-exclusive, non-transferable, and subject to these Terms (which may be amended from time to time) and all other terms and conditions are expressly excluded unless otherwise agreed in writing.</p>
        <p>You may:</p>
        <p>
            <ul>
                <li>temporarily download one copy of the Website content for personal, non-commercial transitory viewing only.</li>
            </ul>
        </p>
        <p>You may not:</p>
        <p>
            <ul>
                <li>Archive, copy, reproduce, distribute, modify, display, publish, licence, create adaptations or derivative works from, offer for sale or use (except as expressly authorised under these Terms) any of the Website content;</li>
                <li>Circumvent, remove, alter, disable, deactivate or otherwise interfere with any security-related features of the Website;</li>
                <li>Use any automated tools or mechanisms including any robot, spider or scraper to access the Website or insert any code or manipulate the Website;</li>
                <li>Reverse engineer, disassemble or decompile any software or software processes in relation to the Website; </li>
                <li>use the Website content for any commercial purpose, or for any public display (commercial or non-commercial);</li>
                <li>remove any copyright or other proprietary notices on the Website;</li>
                <li>Install any viruses, worms, malware or other harmful or destructive software or thing that may impair the functionality of the Website or the ability of others to access and use the Website, Products or Services</li>
            </ul>
        </p>
    </section>
    <section>
        <h3>OUR OPTIONS</h3>
        <p>Under these Terms, We may:</p>
        <p>
            <ul>
                <li>Edit or remove any content, features or functionality of the Website;</li>
                <li>Monitor Your use of the Website; </li>
            </ul>
        </p>
        <p>If You breach these Terms, We may:</p>
        <p>
            <ul>
                <li>Suspend or terminate Your use of the Website; and/or</li>
                <li>Take legal action against You.</li>
            </ul>
        </p>
        <p>We may change, suspend, terminate or restrict access to the Website at any time without notice.  Our No Warranty/Disclaimers and Limitation of Liability will survive such termination.</p>
    </section>
    <section>
        <h3>INTELLECTUAL PROPERTY</h3>
        <p>Unless expressly provided for in these Terms (or separately agreed between us in writing), Your use of this Website or Our Products or Services do not transfer the ownership or grant any right or title in or to any of Our IP or any proprietary and intellectual property rights of any third party licensor and used in the Website <strong>(Third Party IP)</strong> to You or any third party. </p>
        <p>You agree and acknowledge that:</p>
        <p>
            <ul>
                <li>We (and our licensors) retain all right, title and interest in and to Our IP and any Third Party IP and You agree to not attack, dispute or contest the validity, or the ownership, of Our IP or any Third Party IP; and</li>
                <li>no ownership rights in any of Our IP are vested or created by the limited rights of use granted to You under these Terms and that all use of Our IP under these Terms, continues for Our benefit.</li>
            </ul>
        </p>
    </section>
    <section>
        <h3>PRIVACY AND COMMUNICATION</h3>
        <p>By using the Website, You agree to abide by and comply with Our Privacy Statement located at https://farmote.com/. You agree that We can send You information relating to Our Products and Services by using electronic messages or other means. You may unsubscribe from the electronic messaging services at any time by emailing Us at info@farmote.com.</p>
    </section>
    <section>
        <h3>NO WARRANTY/DISCLAIMER</h3>
        <p>To the maximum extent permitted by law, We expressly disclaim and exclude all representations, warranties, conditions and guarantees, including (without limitation) in respect of quality, merchantability, fitness for purpose, condition, description, manufacture, design or performance whether express, implied by common law, law merchant, trade usage, custom or otherwise or statutory in relation to the Website.</p>
        <p>You acknowledge that:</p>
        <p>
            <ul>
                <li>Your use of the Website is at Your sole risk; </li>
                <li>The Website, and all information made available through it, is provided “as is” and “with all faults”; and</li>
                <li>the Website may not be secure, timely, uninterrupted, error-free or otherwise reliable. You are responsible for ensuring the process by which you access and use the Website protects you from any exposure to viruses or other forms of interference which may damage your computer system or expose you to fraud when you access or use the Website;</li>
                <li>We may cease to make available any of the Website content;</li>
                <li>The transmission of information via the internet is not secure and We cannot guarantee the security of Your data in transmission to or from our Website.</li>
            </ul>
        </p>
    </section>
    <section>
        <h3>LINKED SITES</h3>
        <p>This Website may contain links to other websites of which We do not have control and are not governed by these Terms or Our policies. Any link to other websites is not an endorsement of those websites by Us and We are not responsible for the content, accuracy and/or availability on those websites.</p>
        <p>You must obtain our written permission to establish a link to our Website.  If you wish to do so, email your request to info@farmote.com.</p>
    </section>
    <section>
        <h3>INDEMNITY</h3>
        <p>You agree to indemnify us against all liabilities, costs (including full costs between solicitor and client), losses, claims, expenses and demands incurred by us which arise from or in connection with your access or use of the Website, including but not limited to any breach by You of warranties under these Terms, and from any third party claims arising out of or incidental to Your use of this Website and/or failure to comply with these Terms.</p>
    </section>
    <section>
        <h3>LIMITATION OF LIABILITY </h3>
        <p>Despite anything else contained in these Terms, any liability to You by Us in respect of anything arising from or concerning these Terms or the Website or any of the Products or Services, whether arising in tort (including negligence), contract, breach of statutory duty, equity or otherwise arising from any relationship with You <strong>(Our liability)</strong> is excluded to the fullest extent permitted by law.</p>
        <p>To the extent that Our liability cannot be excluded by law, or to the extent that the exclusion of Our liability would render these Terms unenforceable, You agree that the maximum amount that You will claim against Us (in total) and the maximum amount that We (in total) are liable to You for is one New Zealand dollar or the next largest amount that would be needed to render these Terms (including these exclusion and limitation provisions) enforceable for Our benefit.</p>
        <p>Despite anything else contained in these Terms, You agree that We are not liable to You (or to anyone else) for any failure or delay in the performance of Our obligations under these Terms to the extent that the failure or delay is caused, directly or indirectly, by an event outside Our reasonable control. </p>
    </section>
    <section>
        <h3>GENERAL PROVISIONS</h3>
        <p><span className="font-bold">Severability:</span> If any part or provision of these Terms are held to be invalid, illegal or unenforceable that part or provision will be deemed deleted from these Terms and the remainder of these Terms will continue to apply. </p>
        <p><strong>Relationship:</strong> These Terms do not create any relationship of partnership, agency, employment or joint venture between You and Us.</p>
        <p><strong>No waiver:</strong> Any failure or delay by either You or Us in exercising (or in partially exercising) any right, power or remedy arising from a breach of these Terms (Right) does not operate as a waiver of that Right unless that waiver is provided in writing and signed by the party granting the waiver. The existence of any Right of Ours expressly set out in these Terms, or the exercise of such, does not limit or prejudice any other rights, powers or remedies available to Us in contract, at law or in equity, including any rights, powers or remedies that would be available to Us if the right, power, or remedy was not set out in these Terms.</p>
        <p><strong>Assignment:</strong> You may not assign or transfer Your rights or obligations under these Terms without Our prior written consent. We may assign, transfer or novate any of Our rights and obligations under these Terms without Your consent. </p>
        <p><strong>NZ law:</strong> These Terms shall be interpreted in accordance with, and governed by, the laws of New Zealand. Your Use of this Website and the supply of any Products and/or Services to You by Us and any other matter arising from these Terms are subject to the laws of New Zealand.  You agree that any dispute arising from or relating to these Terms is governed by the non-exclusive jurisdiction of the courts of New Zealand.</p>
        <p><strong>Entire Agreement:</strong> Unless We otherwise agree in writing, these Terms constitute the entire agreement between Us and You in relation to their subject matter. </p>
        <p><strong>Your Rights:</strong> The Website’s contents have not been prepared by taking into account the particular situation or needs of any individual users.  Other than Your rights under the Consumer Guarantees Act 1993.</p>
        <p><strong>Contact:</strong>  If You have any questions or concerns in relation to the website, related services or terms and conditions, please contact us via the “Contact Us” link provided on the Website.</p>
    </section>
</div>

export default Terms